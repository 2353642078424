
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
          Stephen Martin
        </p>

        <p class="sm">
          Project Manager | Software Developer | Governor
        </p>
        
      </header>
    </div>
  );
}

export default App;
